import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Aktualisiert den Zustand, um die Fallback-UI zu rendern
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Logge den Fehler, wenn nötig
    console.error("ErrorBoundary caught an error:", error, errorInfo);
    // Optional: Fehler an einen Service wie Sentry senden
  }

  render() {
    if (this.state.hasError) {
      // Benutzerdefinierte Fallback-UI
      return (
        <div style={{ textAlign: "center", padding: "2rem" }}>
          <h1>Oops! Something went wrong.</h1>
          <p>Wir konnten die gewünschte Seite nicht laden. Bitte versuchen Sie es erneut.</p>
          <button onClick={() => window.location.reload()} style={{ padding: "0.5rem 1rem", fontSize: "1rem" }}>
            Seite neu laden
          </button>
        </div>
      );
    }

    // Wenn kein Fehler auftritt, die Kinder-Elemente rendern
    return this.props.children;
  }
}

export default ErrorBoundary;
