import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, logout, currentUser } = useContext(AuthContext);

    useEffect(() => {
        let logoutTimer;

        const resetTimer = () => {
            if (logoutTimer) clearTimeout(logoutTimer);
            logoutTimer = setTimeout(() => {
                logout();
                window.location.href = "/login"; // Zusätzliche Absicherung
            }, 10 * 60 * 1000); // 10 Minuten
        };

        if (isAuthenticated) {
            window.addEventListener("mousemove", resetTimer);
            window.addEventListener("keypress", resetTimer);
            resetTimer();
        }

        return () => {
            clearTimeout(logoutTimer);
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("keypress", resetTimer);
        };
    }, [logout, isAuthenticated]);

    // Prüfe Authentifizierungsstatus und aktuellen Benutzer
    if (!isAuthenticated || !currentUser) {
        return <Navigate to="/login" />;
    }

    // Gibt die geschützten Inhalte zurück, wenn der Benutzer authentifiziert ist
    return children;
};

export default PrivateRoute;
