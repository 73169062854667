import React, { createContext, useState, useEffect } from "react";

// Erstelle den AuthContext
const AuthContext = createContext();

// AuthProvider stellt den Authentifizierungsstatus der App zur Verfügung
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null); // Aktueller Benutzer

  // Funktion zum Einloggen und Speichern des Tokens und Benutzerdaten
  const login = (jwtToken, userInfo) => {
    console.log("Login ausgeführt. Token und Benutzerinformationen werden gesetzt.");
    setToken(jwtToken);
    setCurrentUser(userInfo); // Benutzerinformationen speichern
    setIsAuthenticated(true);
    localStorage.setItem("jwtToken", jwtToken); // Token im localStorage speichern
    localStorage.setItem("userInfo", JSON.stringify(userInfo)); // Benutzerinformationen speichern
  };

  // Funktion zum Ausloggen
  const logout = () => {
    console.log("Logout ausgeführt. Lokale Daten werden entfernt.");
    setToken(null);
    setCurrentUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("jwtToken"); // Token aus dem localStorage entfernen
    localStorage.removeItem("userInfo"); // Benutzerinformationen aus dem localStorage entfernen
  };

  // Token und Benutzerinformationen beim Starten der App aus dem localStorage laden
  useEffect(() => {
    console.log("AuthProvider gestartet. Lade gespeicherte Daten aus dem localStorage...");

    // Token und Benutzerinformationen aus dem localStorage abrufen
    const storedToken = localStorage.getItem("jwtToken");
    const storedUserInfo = localStorage.getItem("userInfo");

    // Debugging-Ausgaben
    console.log("Gefundener Token:", storedToken);
    console.log("Gefundene Benutzerinformationen:", storedUserInfo);

    // Nur weitermachen, wenn gültige Daten vorhanden sind
    if (storedToken && storedUserInfo) {
      try {
        console.log("storedToken:", storedToken);
        console.log("storedUserInfo:", storedUserInfo);
        const parsedUserInfo = JSON.parse(storedUserInfo); // Benutzerinformationen parsen
        setToken(storedToken);
        setCurrentUser(parsedUserInfo); // Benutzerinformationen setzen
        setIsAuthenticated(true);
        console.log("Benutzer erfolgreich eingeloggt mit gespeicherten Daten.");
      } catch (error) {
        console.error("Fehler beim Parsen von userInfo aus localStorage:", error);
        // Fehlerhafte Daten löschen
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("userInfo");
      }
    } else {
      console.log("Keine gespeicherten Authentifizierungsdaten gefunden.");
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, token, currentUser }}
    >
      {children} {/* Die Kinderkomponenten können jetzt den AuthContext verwenden */}
    </AuthContext.Provider>
  );
};

export default AuthContext;
