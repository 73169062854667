import React, { createContext, useState, useContext } from "react";

// Erstelle den ThemeContext
const ThemeContext = createContext();

// Gemeinsame Button-Stile
const commonButtonStyles = {
  borderRadius: "8px",
  padding: "10px 15px",
  margin: "5px 0",
  textAlign: "center",
  cursor: "pointer",
  transition: "transform 0.2s, background-color 0.2s",
  width: "250px",
};

// Definiere die Theme-Stile
const themeStyles = {
  light: {
    backgroundColor: "#437F9A", // Haupt-Hintergrundfarbe
    color: "#264653", // Haupt-Textfarbe
    sidebar: {
      backgroundColor: "#264653",
      color: "#A8DADC",
    },
    display: {
      backgroundColor: "#264653",
      color: "#264653",
    },
    header: {
      backgroundColor: "#264653",
      color: "#264653",
    },
    footer: {
      backgroundColor: "#264653",
      color: "#264653",
    },
    Button: {
      ...commonButtonStyles,
      backgroundColor: "#264653",
      color: "#F4F7F6",
      hover: {
        backgroundColor: "#1D3557",
        transform: "scale(1.05)",
      },
      active: {
        transform: "scale(0.95)",
      },
    },
    input: {
      backgroundColor: "rgba(255, 255, 204, 0.5)", // Halbtransparentes Gelb
      color: "#264653",
      borderColor: "#A8DADC",
    },
  },
  dark: {
    backgroundColor: "#121212", // Sehr dunkles Grau für Haupt-Hintergrund
    color: "#E0E0E0", // Helles Grau für Text
    sidebar: {
      backgroundColor: "#1F1F1F", // Dunkler Sidebar-Hintergrund
      color: "#C7C7C7", // Heller Sidebar-Text
    },
    display: {
      backgroundColor: "#181818", // Sehr dunkler Content-Hintergrund
      color: "#E0E0E0", // Heller Content-Text
    },
    header: {
      backgroundColor: "#242424", // Dunkler Header-Hintergrund
      color: "#E0E0E0", // Heller Header-Text
    },
    footer: {
      backgroundColor: "#242424", // Dunkler Footer-Hintergrund
      color: "#E0E0E0", // Heller Footer-Text
    },
    Button: {
      ...commonButtonStyles,
      backgroundColor: "#1F1F1F", // Sehr dunkles Grau für Buttons
      color: "#E0E0E0", // Heller Button-Text
      hover: {
        backgroundColor: "#2C2C2C", // Leicht helleres Grau beim Hover
        transform: "scale(1.05)",
      },
      active: {
        transform: "scale(0.95)",
      },
    },
    input: {
      backgroundColor: "#1F1F1F", // Sehr dunkler Eingabe-Hintergrund
      color: "#E0E0E0", // Heller Eingabe-Text
      borderColor: "#444444", // Grauer Rand
    },
  },
};

// Provider für den ThemeContext
const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  // Funktion zum Umschalten des Themes
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, themeStyles: themeStyles[theme] }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom Hook, um den ThemeContext zu verwenden
const useTheme = () => {
  return useContext(ThemeContext);
};

export { ThemeContext, ThemeProvider, useTheme };
